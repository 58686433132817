import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, Typography, Button } from '@mui/material';
import "./style.css";
import Header from "./Header/Header";
import MainPanel from './MainPanel/mainpanel';
import Footer from '../../components/footer/footer';

const HomePage = () => {
  const [currentGame, setCurrentGame] = useState('FC25'); // Initial game
  const games = ['FC25', 'Call of Duty']; // Array of games to rotate through

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentGame(current => games[(games.indexOf(current) + 1) % games.length]);
    }, 5000); // Rotate every 5 seconds

    return () => clearInterval(intervalId); // Cleanup on component unmount
  }, []);


  const [currentGameIndex, setCurrentGameIndex] = useState(0);
  const [displayedText, setDisplayedText] = useState('');
  const [isRemoving, setIsRemoving] = useState(false);

  useEffect(() => {
    const nextStep = () => {
      setDisplayedText(current => {
        const targetText = games[currentGameIndex];
        if (isRemoving) {
          if (current.length > 0) {
            return current.substring(0, current.length - 1);
          } else {
            setIsRemoving(false);
            setCurrentGameIndex(i => (i + 1) % games.length);
            return '';
          }
        } else {
          if (current.length < targetText.length) {
            return targetText.substring(0, current.length + 1);
          } else {
            setTimeout(() => setIsRemoving(true), 2000); // Wait a bit before starting to remove text
            return current;
          }
        }
      });
    };

    const intervalId = setInterval(nextStep, 200); // Adjust time for typing speed

    return () => clearInterval(intervalId);
  }, [currentGameIndex, isRemoving, games]);


  return (
    <>
  <div className="container">   
        <Header />
        <div className="mainContainer">
        <MainPanel/>
        </div>
      </div>

      <Footer/>
    </>
  );
};

export default HomePage;
