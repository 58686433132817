import React, { useState } from 'react';
import { Modal, Box, Typography, TextField, Button, Snackbar, Alert } from '@mui/material';
import { Link } from 'react-router-dom';
import './SignupModal.scss'; // Ensure this path is correct
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { signupUser } from '../../utils/apiService';


// Function to validate email format
const isValidEmail = (email) => {
    return /\S+@\S+\.\S+/.test(email);
  };
  

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #783AFE', // Purple border
  boxShadow: 24,
  p: 4,
  display: 'flex',
  flexDirection: 'column', // Organize children in a column
  alignItems: 'center', // Center-align children
  background: 'linear-gradient(270deg, #15161B -0.49%, #18191E 100%)', // Background gradient
};

const SignUpModal = ({ onClose, onSwitchToLogin }) => {
    const [formValues, setFormValues] = useState({
        username: '',
        password: '',
        verifyPassword: '',
        email: ''
      });

      const [errors, setErrors] = useState({});
      const [openSnackbar, setOpenSnackbar] = useState(false);
      const [snackbarMessage, setSnackbarMessage] = useState('');


      const handleChange = (field) => (event) => {
        setFormValues({ ...formValues, [field]: event.target.value });
      
        // If there's an error for this field, remove it entirely when the user starts typing
        if (errors[field]) {
          const updatedErrors = { ...errors };
          delete updatedErrors[field]; // Completely remove the error entry for this field
          setErrors(updatedErrors);
        }
      };
      const handleSubmit = async () => {
        let newErrors = {};
        if (!formValues.username) newErrors.username = 'Username is required';
        if (!formValues.email) {
          newErrors.email = 'Email is required';
        } else if (!isValidEmail(formValues.email)) {
          newErrors.email = 'Invalid email format';
        }
        if (!formValues.password) newErrors.password = 'Password is required';
        if (formValues.password !== formValues.verifyPassword) newErrors.verifyPassword = 'Passwords do not match';
    
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            // Display the first error message found in newErrors
            const firstErrorMessage = newErrors[Object.keys(newErrors)[0]];
            toast.error(firstErrorMessage, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return;
            }

            try {
                // API call to sign up the user
                await signupUser({
                  username: formValues.username,
                  password: formValues.password,
                  email: formValues.email,
                });
            
                // Handle successful sign up
                // Show the success toast
                toast.success('Signup successful!', {
                    position: "top-center",
                    autoClose: 3000, // Toast will close after 3 seconds
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                
                // Close the modal after a delay to allow the toast to be seen
                setTimeout(() => {
                    onClose(); // Close the modal or redirect the user
                }, 3500); // Slightly longer than toast's autoClose to ensure the toast is visible before closing
              } catch (error) {
                // Handle API response errors
                let errorMessage = 'Signup failed. Please try again later.';
                if (error && error.message) {
                  errorMessage = error.message;
                }
                toast.error(errorMessage, {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              }
      };

      const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
      };
    

  return (
    <Modal
      open={true}
      onClose={onClose}
      aria-labelledby="signup-modal-title"
      aria-describedby="signup-modal-description"
    >
      <Box sx={style}>
                        {/* Error display using Snackbar */}
                        <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
          <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
        <Typography id="signup-modal-title" variant="h6" component="h2" color="white">
          Sign Up
        </Typography>
        <TextField 
    label="Username" 
    variant="outlined" 
    fullWidth 
    margin="normal" 
    value={formValues.username} // Control component with React state
    onChange={handleChange('username')} // Use handleChange to update state and clear errors
    InputLabelProps={{style: {color: 'white'}}} 
    inputProps={{style: {color: 'white', backgroundColor: 'grey', borderRadius: '5px'}}}
    error={!!errors.username} // Show error state if there's an error for this field
    helperText={errors.username} // Display the error message
/>
<TextField 
    label="Password" 
    type="password" 
    variant="outlined" 
    fullWidth 
    margin="normal" 
    value={formValues.password}
    onChange={handleChange('password')}
    InputLabelProps={{style: {color: 'white'}}} 
    inputProps={{style: {color: 'white', backgroundColor: 'grey', borderRadius: '5px'}}}
    error={!!errors.password}
    helperText={errors.password}
/>
<TextField 
    label="Verify Password" 
    type="password" 
    variant="outlined" 
    fullWidth 
    margin="normal" 
    value={formValues.verifyPassword}
    onChange={handleChange('verifyPassword')}
    InputLabelProps={{style: {color: 'white'}}} 
    inputProps={{style: {color: 'white', backgroundColor: 'grey', borderRadius: '5px'}}}
    error={!!errors.verifyPassword}
    helperText={errors.verifyPassword}
/>
<TextField 
    label="Email" 
    type="email" 
    variant="outlined" 
    fullWidth 
    margin="normal" 
    value={formValues.email}
    onChange={handleChange('email')}
    InputLabelProps={{style: {color: 'white'}}} 
    inputProps={{style: {color: 'white', backgroundColor: 'grey', borderRadius: '5px'}}}
    error={!!errors.email}
    helperText={errors.email}
/>

        
        
        <Box sx={{ display: 'flex', justifyContent: 'space-around', width: '100%', mt: 2 }}>
          <Button
            onClick={onClose} 
            variant="outlined"
            color="inherit"
            sx={{ width: 'calc(50% - 8px)', mr: 1, borderColor: 'white', color: 'white' }}> 
            Close
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            sx={{ width: 'calc(50% - 8px)', ml: 1, background: '#783AFE', '&:hover': { background: 'rgba(120, 58, 254, 0.8)' } }}> 
            Sign Up
          </Button>
        </Box>
        
        <Typography variant="body2" sx={{ mt: 2, color: 'white' }}>
  If you already have an account...
  <span
    onClick={onSwitchToLogin} // This function will be provided by Header.js
    style={{ cursor: 'pointer', textDecoration: 'underline', color: '#783AFE' }}
  >
    Log in
  </span>
</Typography>


        <ToastContainer />
      </Box>
    </Modal>
  );
};

export default SignUpModal;
