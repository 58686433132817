import React from 'react';
import './footer.scss'; // Change the extension to .css if that's what you are using

const Footer = () => {
  return (
    <div className="footerWrapper">
      <footer className="footer">
        <div className="footerLeft">
          <p className="copyRight">
            NEXLEVEL.DEV
          </p>
          <p className="copyRightInfo">
            Copyright © NEXLEVEL.DEV 2024 | All Rights Reserved
          </p>
        </div>
        <div className="footerRight">
          <a className="terms" href="/terms-of-service">TERMS OF SERVICE</a>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
