import React from 'react';
import { Modal, Box, Typography, TextField, Button } from '@mui/material';
import './loginModal.scss'; // Ensure this path matches your file structure
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000', // Example border, adjust as needed
  boxShadow: 24,
  p: 4,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  // Add any other styles you need
};

const LoginModal = ({ onClose }) => {
  return (
    <Modal
      open={true}
      onClose={onClose}
      aria-labelledby="login-modal-title"
      aria-describedby="login-modal-description"
    >
      <Box sx={style}>
        <Typography id="login-modal-title" variant="h6" component="h2">
          Log In
        </Typography>
        {/* Add more content such as TextFields for username and password, and login button */}
        <TextField label="Username" variant="outlined" fullWidth margin="normal" />
        <TextField label="Password" type="password" variant="outlined" fullWidth margin="normal" />
        <Button variant="contained" color="primary" fullWidth>
          Log In
        </Button>
        <ToastContainer />
      </Box>
    </Modal>
  );
};

export default LoginModal;
