import React from 'react';
import { useLocation } from 'react-router-dom';

const NotFoundPage = () => {
  const location = useLocation();

  console.log('Attempted to access:', location.pathname);

  return (
    <div>
      <h1>404 Not Found</h1>
      <p>The page you are looking for does not exist.</p>
      <p>Attempted to access: {location.pathname}</p>
    </div>
  );
};

export default NotFoundPage;