import axios from 'axios';

// Access the environment variable
const API_BASE_URL = process.env.REACT_APP_ORIGIN_URL || 'https://bluelockfc.com/';
console.log(process.env.REACT_APP_ORIGIN_URL);
// Initialize axios with the API base URL
const api = axios.create({
  baseURL: API_BASE_URL + 'api/',
});

// Set the AUTH token for any request
api.interceptors.request.use(function (config) {
  const token = localStorage.getItem('token');
  config.headers.Authorization = token ? `Bearer ${token}` : '';
  return config;
});

export const loginUser = async (username, password) => {
  try {
    const response = await api.post('webuser/login', { username, password });
    return response.data;
  } catch (error) {
    // Check if the error response has a message and throw it
    if (error.response && error.response.data && error.response.data.message) {
      throw new Error(error.response.data.message);
    } else {
      // If no specific message, throw a general error message
      throw new Error('Login failed. Please try again later.');
    }
  }
};

export const updateSubscriptionIsPaid = async (subscriptionId, isPaid) => {
  try {
    const response = await api.put(`subscription/${subscriptionId}/ispaid`, { isPaid });
    return response.data;
  } catch (error) {
    if (error.response && error.response.data && error.response.data.message) {
      throw new Error(error.response.data.message);
    } else {
      throw new Error('Failed to update subscription status. Please try again later.');
    }
  }
};

export const getDuplicateUsers = async () => {
  try {
    const response = await api.get('user/duplicates');
    return response.data;
  } catch (error) {
    if (error.response && error.response.data && error.response.data.message) {
      throw new Error(error.response.data.message);
    } else {
      throw new Error('Failed to fetch duplicate user information. Please try again later.');
    }
  }
};

export const isTokenValid = async (token) => {
    try {
      const response = await api.post('webuser/validate-token', { token });
      return response.data.isValid; 
    } catch (error) {
      throw error;
    }
  };

  export const getUsers = async (page = 1, limit = 10, searchTerm = '') => {
    try {
      const response = await api.get(`user`, {
        params: { page, size: limit , search: searchTerm }
      });
      return response.data;
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
        throw new Error(error.response.data.message);
      } else {
        throw new Error('Failed to fetch users. Please try again later.');
      }
    }
  };
  
  export const getGames = async (page = 1, limit = 10, searchTerm = '') => {
    try {
      const response = await api.get('game', { // Update the endpoint as per your API
        params: {
          page: page,
          size: limit,
          search: searchTerm // If your API supports search functionality
        }
      });
      return response.data;
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
        throw new Error(error.response.data.message);
      } else {
        throw new Error('Failed to fetch games. Please try again later.');
      }
    }
  };

  export const addGame = async (gameData) => {
    try {
      const response = await api.post('game', gameData); // Update 'games' to your actual endpoint
      return response.data;
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
        throw new Error(error.response.data.message);
      } else {
        throw new Error('Failed to add game. Please try again later.');
      }
    }
  };

export const deleteGame = async (gameId) => {
  try {
    const response = await api.delete(`game/${gameId}`); // Make sure the endpoint matches your server route
    return response.data;
  } catch (error) {
    if (error.response && error.response.data && error.response.data.message) {
      throw new Error(error.response.data.message);
    } else {
      throw new Error('Error deleting the game. Please try again later.');
    }
  }
};

export const deleteUser = async (userId) => {
  try {
    const response = await api.delete(`user/${userId}`); // Replace 'games' with your actual endpoint
    return response.data;
  } catch (error) {
    if (error.response && error.response.data && error.response.data.message) {
      throw new Error(error.response.data.message);
    } else {
      throw new Error('Failed to delete the game. Please try again later.');
    }
  }
};

export const getBlacklistEntries = async (page = 1, limit = 10, searchTerm = '') => {
  try {
    const response = await api.get('blacklist', {
      params: { page, size: limit, search: searchTerm }
    });
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

export const addBlacklistEntry = async (type, value) => {
  try {
    const response = await api.post('blacklist', { type, value });
    return response.data;
  } catch (error) {
    // Handle errors here. You can either throw the error or return a specific message.
    throw error;
  }
};
export const deleteBlacklistEntry = async (id) => {
  try {
    const response = await api.delete(`blacklist/${id}`);
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};


export const addSubscription = async (subscriptionData) => {
  try {
    const response = await api.post('subscription', subscriptionData);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const getSubscriptions = async (page = 1, limit = 10, searchTerm = '', includeTrials = false) => {
  try {
    const response = await api.get('subscription', {
      params: { page, limit, search: searchTerm, includeTrials }
    });
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const updateSubscription = async (id, subscriptionData) => {
  try {
    const response = await api.put(`subscription/${id}`, subscriptionData);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const deleteSubscription = async (id) => {
  try {
    const response = await api.delete(`subscription/${id}`);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const getRemainingSubscriptions = async () => {
  try {
    const response = await api.get('webuser/remainingSubscriptions');
    return response.data;
  } catch (error) {
    if (error.response && error.response.data && error.response.data.message) {
      throw new Error(error.response.data.message);
    } else {
      throw new Error('Failed to fetch remaining subscriptions. Please try again later.');
    }
  }
};

export const deleteTrialOnlyUsers = async () => {
  try {
    const response = await api.delete('user/deleteTrialOnlyUsers');
    return response.data;
  } catch (error) {
    if (error.response && error.response.data && error.response.data.message) {
      throw new Error(error.response.data.message);
    } else {
      throw new Error('Failed to delete users with only a trial subscription. Please try again later.');
    }
  }
};

export const signupUser = async ({ username, password, email }) => {
  try {
    const response = await api.post('user/signup', {
      username,
      password,
      email
    });
    return response.data; // Assuming successful response is JSON and you want the data part
  } catch (error) {
    // Directly handling plain text error message
    if (error.response && error.response.data) {
      // Check if the response is plain text and directly throw it as an error message
      if (typeof error.response.data === 'string') {
        throw new Error(error.response.data);
      }
      // Additional condition if your API might also return error details in JSON format
      else if (error.response.data.message) {
        throw new Error(error.response.data.message);
      } else {
        // Handling unexpected response formats
        throw new Error('Signup failed. Please try again later.');
      }
    } else {
      // Fallback error message for network issues or other errors without a response
      throw new Error('Signup failed. Please check your network and try again.');
    }
  }
};


export const getLicenseKeys = async (page = 1, limit = 10, searchTerm = '') => {
  try {
    const response = await api.get('licensekey', { params: { page, size: limit , search: searchTerm }});
    return response.data;
  } catch (error) {
    if (error.response && error.response.data && error.response.data.message) {
      throw new Error(error.response.data.message);
    } else {
      throw new Error('Failed to fetch License Keys. Please try again later.');
    }
  }
};

export const createLicenseKey = async (gameId, type, licenseKeyNumber) => {
  try {
    const response = await api.post('licensekey/create', {
      gameId: gameId,
      type: type,
      licenseKeyNumber: licenseKeyNumber
    });
    return response.data; 
  } catch (error) {
    if (error.response && error.response.data && error.response.data.message) {
      throw new Error(error.response.data.message);
    } else {
      throw new Error('Failed to create license keys. Please try again later.');
    }
  }
};

export const getGamesAndPrices = async (page = 1, limit = 10, searchTerm = '') => {
  try {
    const response = await api.get('game/prices', { // Update the endpoint as per your API
      params: {
        page: page,
        size: limit
      }
    });
    return response.data;
  } catch (error) {
    if (error.response && error.response.data && error.response.data.message) {
      throw new Error(error.response.data.message);
    } else {
      throw new Error('Failed to fetch games. Please try again later.');
    }
  }
};

export const resetHwid = async (licenseKey) => {
  try {
    const response = await api.patch(
      'licensekey/reset-hwid-by-key', // Endpoint de la requête PATCH
      { licenseKey }
    );
    return response.data; // Retourner les données de la réponse en cas de succès
  } catch (error) {
    // Vérifier si l'erreur a une réponse et si cette réponse a un objet de données avec un message d'erreur
    if (error.response && error.response.data && error.response.data.error) {
      throw new Error(error.response.data.error); // Extraire le message d'erreur et le lancer comme une exception
    } else if (error.response && error.response.data && error.response.data.message) {
      // Si le message d'erreur est stocké sous un autre nom
      throw new Error(error.response.data.message);
    } else {
      throw new Error('Failed to reset HWID. Please try again later.'); // Message générique pour les autres cas
    }
  }
};

export const disableLicenseKey = async (licenseKey, reason) => {
  try {
    const response = await api.patch(
      'licensekey/disable-key', // Endpoint de la requête PATCH
      { licenseKey, reason }
    );
    return response.data; // Retourner les données de la réponse en cas de succès
  } catch (error) {
    // Vérifier si l'erreur a une réponse et si cette réponse a un objet de données avec un message d'erreur
    if (error.response && error.response.data && error.response.data.error) {
      throw new Error(error.response.data.error); // Extraire le message d'erreur et le lancer comme une exception
    } else if (error.response && error.response.data && error.response.data.message) {
      // Si le message d'erreur est stocké sous un autre nom
      throw new Error(error.response.data.message);
    } else {
      throw new Error('Failed to Disable Key. Please try again later.'); // Message générique pour les autres cas
    }
  }
};

export const getBalance = async () => {
  try {
    const response = await api.get('webuser/balance');
    return response.data.balance; // Retourner le solde récupéré
  } catch (error) {
    if (error.response && error.response.data && error.response.data.error) {
      throw new Error(error.response.data.error); // Traiter l'erreur et extraire le message
    } else {
      throw new Error('Failed to fetch user balance. Please try again later.'); // Message générique en cas d'échec
    }
  }
};

// Function to get a page by slug
export const getPageBySlug = async (slug) => {
  try {
    const response = await api.get(`page/${slug}`); // Endpoint to fetch page by slug
    return response.data; // Return the page data
  } catch (error) {
    // Handle errors and throw appropriate messages
    if (error.response && error.response.data && error.response.data.message) {
      throw new Error(error.response.data.message);
    } else {
      throw new Error('Failed to retrieve the page. Please try again later.');
    }
  }
};

export const getCheatBySlug = async (slug) => {
  try {
    const response = await api.get(`page/cheats/${slug}`); // Endpoint to fetch page by slug
    return response.data; // Return the page data
  } catch (error) {
    // Handle errors and throw appropriate messages
    if (error.response && error.response.data && error.response.data.message) {
      throw new Error(error.response.data.message);
    } else {
      throw new Error('Failed to retrieve the page. Please try again later.');
    }
  }
};