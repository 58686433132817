import React, { useState, useEffect } from 'react';
import './DisplayKeysComponent.scss';
import { getLicenseKeys } from '../../../../../utils/apiService';
import { format } from 'date-fns'; // Import de la bibliothèque
import { enUS } from 'date-fns/locale'; // Importation de la locale souhaitée (anglais américain ici)

function DisplayKeysComponent({ userRole }) {
  const [keysData, setkeysData] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [limit, setLimit] = useState(10); // Default items per page
  const [searchTerm, setSearchTerm] = useState(''); // Search term state
  const [hideUsed, setHideUsed] = useState(false); // Hide used keys
  const [hideExpired, setHideExpired] = useState(false); // Hide expired keys
  const [hideUnused, setHideUnused] = useState(false); // Hide unused keys

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  useEffect(() => {
    fetchLicenseKeys();
  }, [currentPage, limit, searchTerm, hideUsed, hideExpired, hideUnused]);

  const fetchLicenseKeys = async () => {
    try {
      const response = await getLicenseKeys(currentPage, limit, searchTerm);

      const sortedKeys = response.data.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt) // Tri décroissant
      );

      setTotalPages(response.totalPages);
      console.log(response);
      setkeysData(sortedKeys); // Assuming the response is an object with a 'keys' property
    } catch (error) {
      console.error('Error fetching license keys:', error);
      setErrorMessage('Failed to fetch license keys. Please try again.');
    }
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value); // Update search term as user types
  };

  return (
    <div className="main-container">
      <main className="flex-grow padding-large">
        <div className="keys-table-container">
          <div className="search-bar">
            <input
              className="search-input"
              placeholder="Search for a key"
              id="search"
              type="search"
              onChange={handleSearchChange}
            />
          </div>

          <table className="keys-table">
            <thead className="table-header">
              <tr>
                {userRole === 'admin' && <th className="table-header-cell">Seller</th>}
                <th className="table-header-cell">Key</th>
                <th className="table-header-cell">Used</th>
                <th className="table-header-cell">Duration</th>
                <th className="table-header-cell">Game</th>
                <th className="table-header-cell">Creation</th>
                <th className="table-header-cell">Expiration</th>
                <th className="table-header-cell">Paid</th> {/* New column header for Paid */}
              </tr>
            </thead>
            <tbody className="table-body">
              {keysData.map((key) => (
                <tr key={key.id} className="table-row">
                  {userRole === 'admin' && (
                    <td className="table-cell">{key.reseller.username}</td>
                  )}
                  <td className="table-cell">{key.key}</td>
                  <td className="table-cell">{key.status}</td>
                  <td className="table-cell">{key.type}</td>
                  <td className="table-cell">{key.game.name}</td>
                  <td className="table-cell">
                    {format(new Date(key.createdAt), 'MMM d, yyyy h:mm a', { locale: enUS })}
                  </td>
                  <td className="table-cell">
                    {key.expiresAt
                      ? format(new Date(key.expiresAt), 'MMM d, yyyy h:mm a', { locale: enUS })
                      : 'N/A'}
                  </td>
                  <td className="table-cell" style={{ color: key.paid ? 'green' : 'red' }}>
                    {key.paid ? 'YES' : 'NO'}
                  </td> {/* Display paid status with conditional styling */}
                </tr>
              ))}
            </tbody>
          </table>

          <div className="pagination-container"> {/* Pagination Controls */}
            <button
              className={`previous-button ${currentPage === 1 ? 'disabled' : ''}`}
              onClick={handlePreviousPage}
              disabled={currentPage === 1}
            >
              Previous
            </button>
            <span className="pagination-info">Page {currentPage} of {totalPages}</span>
            <button
              className={`next-button ${currentPage === totalPages ? 'disabled' : ''}`}
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
            >
              Next
            </button>
          </div>
        </div>
      </main>
    </div>
  );
}

export default DisplayKeysComponent;
