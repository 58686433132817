import React, { useEffect, useState } from 'react';
import { getProductsByShopSlug } from '../../utils/apiService'; // Import the function to fetch products
import './DynamicShop.scss'; // Styles for the shop page

const DynamicShop = ({ shopData }) => {
  const { slug, title } = shopData; // Extract shop-specific data
  const [cheats, setCheats] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        // const productList = await getProductsByShopSlug(slug); // Fetch products by shop slug
        //setProducts(productList);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchProducts(); // Fetch products on component mount
  }, [slug]);

  if (loading) {
    return <div>Loading products...</div>; // Loading message while fetching data
  }

  if (error) {
    return <div>Error fetching products: {error}</div>; // Error message if fetching fails
  }

  return (
<div className="shop-page">
    <div className="shop-header">
        <div className='shop-header-title'>
            <img width="504" height="191" src="../assets/backgrounds/purplespark.svg" alt="" className="hero-image"/>
            <h1>{title}</h1> {/* Display shop title */}
        </div>
        <p className="description">{shopData.description}</p>

    </div>
    <div className="product-list-wrapper">
    <div className="product-grid">
          {shopData.cheats.map(cheat => (
            <div className="product-item" key={cheat.slug}>
              <a href={`/cheats/${cheat.slug}`}>
                <img src={cheat.imageUrl} alt={cheat.name} className="product-image"/>
              </a>
              <div className="product-info">
                <p>{cheat.name}</p>
              </div>
              <a className='full-width-link' href={`/cheats/${cheat.slug}`}>
                <button className="buy-now">BUY NOW</button>
              </a>
            </div>
          ))}
        </div>
</div>

</div>

  );
};

export default DynamicShop;
