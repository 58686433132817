import React, { createContext, useState, useContext } from 'react';
import { jwtDecode } from "jwt-decode";
import { isTokenValid } from '../utils/apiService';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState({ token: null, isAuthenticated: false });

  const login = (token) => {
    setAuth({ token, isAuthenticated: true });
    localStorage.setItem('token', token);
  };

  const logout = () => {
    setAuth({ token: null, isAuthenticated: false });
    localStorage.removeItem('token');
  };

  const getUserRole = async () => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const isValid = await isTokenValid(token);
        if (isValid) {
          const decoded = jwtDecode(token);
          return decoded.role; // Assuming the role is stored in the 'role' field
        }
      } catch (error) {
        console.error('Failed to validate or decode token:', error);
        // Handle the invalid token case, e.g., by logging out the user
        logout();
      }
    }
    return null;
  };

  const getUserName = async () => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const isValid = await isTokenValid(token);
        if (isValid) {
          const decoded = jwtDecode(token);
          return decoded.name; // Assuming the role is stored in the 'role' field
        }
      } catch (error) {
        console.error('Failed to validate or decode token:', error);
        // Handle the invalid token case, e.g., by logging out the user
        logout();
      }
    }
    return null;
  };

  const getUserId = async () => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const isValid = await isTokenValid(token);
        if (isValid) {
          const decoded = jwtDecode(token);
          return decoded.id; 
        }
      } catch (error) {
        console.error('Failed to validate or decode token:', error);
        // Handle the invalid token case, e.g., by logging out the user
        logout();
      }
    }
    return null;
  };

  const validateToken = async () => {
    
    const token = localStorage.getItem('token');
    if (token) {
        
      try {
        
        const isValid = await isTokenValid(token); // isTokenValid should be an API call
        if (isValid) {
          setAuth({ token, isAuthenticated: true });
        }
      } catch (error) {
        console.error('Token validation error:', error);
        logout(); // Call logout if token is invalid
      }
    }
  };

  return (
    <AuthContext.Provider value={{ auth, login, logout, getUserRole, validateToken, getUserName, getUserId}}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
