import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { loginUser } from '../../utils/apiService';
import { useAuth } from '../../context/AuthContext';
import './Login.scss';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();
  const { login, validateToken, auth } = useAuth();

  useEffect(() => {
    const validateAndRedirect = async () => {
      
      await validateToken();
      if (auth.isAuthenticated) {
        navigate('/dashboard'); // Redirect to dashboard if already authenticated
      }
    };

    validateAndRedirect();
  }, [validateToken, auth.isAuthenticated, navigate]);

  const handleLogin = async (e) => {
    e.preventDefault();
    setErrorMessage(''); // Reset error message on a new login attempt
    try {
      const data = await loginUser(username, password);
      login(data.token); // Set token in context
      navigate('/dashboard');
    } catch (error) {
      // Update the error message state to display the error
      console.log(error)
      setErrorMessage('Login failed: ' + error.message);
    }
  };

  return (
    <div className="container_login">
      <div className="wrap">
        <h2>Login</h2>
        <form onSubmit={handleLogin}>
          <div>
            <label>Username:</label>
            <input
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
          <div>
            <label>Password:</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <button className="login-form-btn" type="submit">Login</button>
        </form>
        {errorMessage && <div className="error-message">{errorMessage}</div>}
      </div>
    </div>
  );
};

export default Login;
