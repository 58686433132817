// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("assets/fonts/Arame.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("assets/fonts/Arame.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("assets/fonts/Arame.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Open+Sans);"]);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*@tailwind base;
@tailwind components;
@tailwind utilities;
*/

@font-face {
    font-family: 'Arame';
    src: local('Arame Regular'), local('Arame-Regular'),
        url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('woff2'),
        url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format('woff'),
        url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format('truetype');
    font-weight: 400;
    font-style: normal;
  }
  
  .arame-font {
    font-family: "Arame", sans-serif; /* Always include a fallback */
  }

body {
    margin: 0;
    padding: 0;
    font-family: "Open Sans", sans-serif;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;;;CAGC;;AAID;IACI,oBAAoB;IACpB;;;kEAGoD;IACpD,gBAAgB;IAChB,kBAAkB;EACpB;;EAEA;IACE,gCAAgC,EAAE,8BAA8B;EAClE;;AAEF;IACI,SAAS;IACT,UAAU;IACV,oCAAoC;AACxC","sourcesContent":["/*@tailwind base;\n@tailwind components;\n@tailwind utilities;\n*/\n\n@import url(https://fonts.googleapis.com/css?family=Open+Sans);\n\n@font-face {\n    font-family: 'Arame';\n    src: local('Arame Regular'), local('Arame-Regular'),\n        url('assets/fonts/Arame.woff2') format('woff2'),\n        url('assets/fonts/Arame.woff') format('woff'),\n        url('assets/fonts/Arame.ttf') format('truetype');\n    font-weight: 400;\n    font-style: normal;\n  }\n  \n  .arame-font {\n    font-family: \"Arame\", sans-serif; /* Always include a fallback */\n  }\n\nbody {\n    margin: 0;\n    padding: 0;\n    font-family: \"Open Sans\", sans-serif;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
