import React, { useState, useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

const PrivateRoute = ({ children, allowedRoles }) => {
  const { auth, getUserRole } = useAuth();
  const location = useLocation();
  const [userRole, setUserRole] = useState(null);
  const [isRoleChecked, setIsRoleChecked] = useState(false);

  useEffect(() => {
    const fetchUserRole = async () => {
      const role = await getUserRole();
      setUserRole(role);
      setIsRoleChecked(true);
    };

    if (auth.isAuthenticated) {
      fetchUserRole();
    } else {
      setIsRoleChecked(true); // User is not authenticated, no need to check role
    }
  }, [auth]);

  // Redirect to login if not authenticated
  if (!auth.isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  // Wait until the user role has been checked
  if (!isRoleChecked) {
    return <div>Loading...</div>; // Or any other loading indicator
  }

  // Check if the user's role is not in the allowed roles
  if (allowedRoles && !allowedRoles.includes(userRole)) {
    return <Navigate to="/unauthorized" />;
  }

  return children; // If the user is authenticated and has the right role, render the children
};

export default PrivateRoute;
