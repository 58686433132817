import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import { getPageBySlug } from '../../utils/apiService';
import Header from '../Home/Header/Header';
import DynamicShop from '../Shop/DynamicShop'; // Import the DynamicShop component
import Footer from '../../components/footer/footer';

const DynamicPage = () => {
  const { pageName } = useParams();
  const [pageData, setPageData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPageData = async () => {
      try {
        const data = await getPageBySlug(pageName);
        setPageData(data);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchPageData();
  }, [pageName]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  // Extract meta information to avoid duplication
  const metaInfo = (
    <Helmet>
      <title>{pageData.metaTitle || pageData.title}</title>
      <meta name="description" content={pageData.metaDescription || ''} />
      {pageData.metadata &&
        Object.entries(pageData.metadata).map(([key, value]) => (
          <meta name={key} content={value} key={key} />
        ))}
      {pageData.schemaMarkup && (
        <script type="application/ld+json">
          {JSON.stringify(pageData.schemaMarkup)}
        </script>
      )}
    </Helmet>
  );

  return (
    <>
    <div className="container">
      <Header />
      {metaInfo} {/* Common meta information */}

      {/* Conditional rendering based on page type */}
      {pageData.type === 'shop' ? (
        <DynamicShop shopData={pageData} /> 
      ) : (
        <>
          <h1>{pageData.title}</h1>
          <div dangerouslySetInnerHTML={{ __html: pageData.content }} />
        </>
      )}
    </div>
    <Footer/>
    </>
  );
};

export default DynamicPage;
