import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AuthProvider, useAuth } from '../src/context/AuthContext' 
import PrivateRoute from './components/PriveteRoute';
import Dashboard from './pages/Dashboard/Dashboard';
import Login from './pages/Dashboard/Login';
import HomePage from './pages/Home/Home'; 
import NotFoundPage from './pages/NotFound';
import DynamicPage from './pages/Dynamic/DynamicPageComponent';
import ProductPage from './pages/Product/ProductPage';
// Import other components and pages as needed

const App = () => {
  return (
    <AuthProvider>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/login" element={<Login />} />

          {/* Private routes */}
          <Route 
            path="/dashboard" 
            element={
              <PrivateRoute allowedRoles={['admin', 'reseller', 'moderator']}>
                <Dashboard />
              </PrivateRoute>
            } 
          />

            {/* Route for cheat details */}
            <Route path="/cheats/:slug" element={<ProductPage />} />

           {/* New dynamic page route */}
           <Route path="/:pageName" element={<DynamicPage />} /> {/* Dynamic route */}

          {/* Add more private and public routes as needed */}
          <Route path="*" element={<NotFoundPage />} /> {/* Catch-all route */}
        </Routes>
    </AuthProvider>
  );
};

export default App;
