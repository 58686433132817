import React, { useState, useEffect } from 'react';
import './CreateKeysComponent.scss'
import { createLicenseKey, getGames, getGamesAndPrices } from '../../../../utils/apiService';

function LicenseKeyComponent({ onBalanceUpdate }) {

    const [games, setGames] = useState([]);
    const [selectedGame, setSelectedGame] = useState({});
    const [quantity, setQuantity] = useState(1);
    const [selectedDuration, setSelectedDuration] = useState('daily');
    const [price, setPrice] = useState(0);
    const [createdKeys, setCreatedKeys] = useState('');

    useEffect(() => {
        const fetchGames = async () => {
            try {
                const response = await getGamesAndPrices();
                const fetchedGames = response.data || [];

                if (fetchedGames.length > 0) {
                    setGames(fetchedGames); // Store the games in state
                    setSelectedGame(fetchedGames[0]); // Default to the first game
                    const resellerPrice = fetchedGames[0]?.ResellerPrices?.[0]; // Default ResellerPrices
                    setPrice(resellerPrice?.daily || 0); // Default price
                }
            } catch (error) {
                console.error('Error fetching games:', error.message);
            }
        };

        fetchGames(); // Fetch games once on component mount
    }, []); // No dependencies: this effect runs once on mount


    const handleGameChange = (event) => {
        const gameName = event.target.value; // Get the game name from the select value
        const newSelectedGame = games.find(game => game.name === gameName); // Find the matching game object
        setSelectedGame(newSelectedGame); // Update selectedGame with the whole object
        setSelectedDuration(''); // Reset selected duration
    };
    



    const handleQuantityChange = (event) => {
        let newQuantity = event.target.value.trim(); // Retirer les espaces
      
        if (newQuantity === '') {
          setQuantity(0); // Si la quantité est vide, la mettre à zéro
          setPrice(0); // Réinitialiser le prix si le champ est vide
        } else {
          newQuantity = parseInt(newQuantity, 10); // Convertir en nombre
      
          if (isNaN(newQuantity) || newQuantity < 0) {
            newQuantity = 0; // Éviter les valeurs négatives ou NaN
          }
      
          setQuantity(newQuantity); // Mettre à jour la quantité
      
          // Mettre à jour le prix si une durée est sélectionnée
          if (selectedDuration && selectedGame?.ResellerPrices?.[0]) {
            const priceForDuration = selectedGame.ResellerPrices[0][selectedDuration];
            const totalPrice = priceForDuration * newQuantity; // Calculer le prix total
            setPrice(totalPrice); // Mettre à jour le prix
          }
        }
      };
      

    const handleDurationClick = (duration) => {
        const resellerPrice = selectedGame?.ResellerPrices?.[0]; // Vérifiez ResellerPrices

        if (!resellerPrice) {
          console.warn("No reseller price found"); // Avertissement s'il n'y a pas de prix
          return;
        }
        const priceForDuration = resellerPrice?.[duration]; // Vérifiez le prix pour la durée
        if (priceForDuration > 0) {
          setSelectedDuration(duration); // Enregistrez la durée sélectionnée
          setPrice(priceForDuration * quantity); // Mettez à jour le prix selon la quantité
        } else {
          console.warn(`No valid price for duration: ${duration}`); // Message si pas de prix
        }
    };

    const handleCopy = () => {
        navigator.clipboard.writeText(createdKeys).then(
          () => {
            console.log('Keys copied to clipboard');
          },
          (err) => {
            console.error('Could not copy text:', err);
          }
        );
      };

      const handleDownload = () => {
        const element = document.createElement('a');
        const file = new Blob([createdKeys], { type: 'text/plain' });
        element.href = URL.createObjectURL(file);
        element.download = 'license_keys.txt';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
      };

    const handleCreate = async () => {

          try {
            const response = await createLicenseKey(selectedGame.id, selectedDuration, quantity);
            const keys = response.licenseKeys.map((k) => k.key).join('\n');
            setCreatedKeys(keys);
            if (onBalanceUpdate) {
                await onBalanceUpdate(); // Appeler la fonction de mise à jour du solde
              }
          } catch (error) {
            console.error('Error creating keys:', error);
          }
      };

    return (
        <div className='horizontal-center'>
            <div className="create-key-container">
                <div className='create-key-container-title'>

                    <h1>Create Keys</h1>
                </div>

                <div className="create-key-form">
                    <div className="form-section">
                        <div className='form-select-game'>
                            <div className="form-group">
                                <label>Game</label>
                                <select
                                    value={selectedGame.name}
                                    onChange={handleGameChange}
                                    className="game-select"
                                >
                                    {games.map(game => (
                                    <option key={game.id} value={game.name}>
                                        {game.fullname}
                                    </option>
                                ))}
                                </select>
                            </div>
                            <img
                                src={`../assets/icons/games/${selectedGame.name}.png`} // Default to 'dayz'
                                className="game-icon"
                                width="100"
                                height="100"
                                alt={`${selectedGame.name || 'dayz'} icon`}
                            />
                        </div>
                    </div>

                    <label>Duration</label>
                    <div className="subscription-duration">
  {selectedGame?.ResellerPrices?.[0] ? (
    <>
      <div className="subscription-row">
        {['daily', 'weekly', 'monthly'].map((duration) => {
          const resellerPrice = selectedGame?.ResellerPrices?.[0];
          const priceForDuration = resellerPrice?.[duration];

          return (
            <div
              key={duration}
              className={`duration-block ${
                selectedDuration === duration ? 'selected' : ''
              } ${priceForDuration <= 0 ? 'disabled' : ''}`} // Désactiver si le prix est 0
              onClick={() => handleDurationClick(duration)}
            >
              <div className="duration-content">
                <h5>
                  {duration === 'daily' ? '1 day' : duration === 'weekly' ? '7 days' : 'A month'}
                </h5>
                {priceForDuration > 0 && (
                  <div className="pricing">
                    <span className="old-price">49.99€</span> {/* Exemple de prix barré */}
                    <span className="new-price">{priceForDuration}€</span> {/* Affichez le prix réel */}
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>

      <div className="subscription-row">
        {['threeMonths', 'lifetime'].map((duration) => {
          const resellerPrice = selectedGame?.ResellerPrices?.[0];
          const priceForDuration = resellerPrice?.[duration];

          return (
            <div
              key={duration}
              className={`duration-block ${
                selectedDuration === duration ? 'selected' : ''
              } ${priceForDuration <= 0 ? 'disabled' : ''}`} // Désactiver si le prix est 0
              onClick={() => handleDurationClick(duration)}
            >
              <div className="duration-content">
                <h5>{duration === 'threeMonths' ? '3 months' : 'Lifetime'}</h5>
                {priceForDuration > 0 && (
                  <div className="pricing">
                    <span className="old-price">89.99€</span> {/* Exemple de prix barré */}
                    <span className="new-price">{priceForDuration}€</span> {/* Affichez le prix réel */}
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </>
  ) : (
    <p>You're not allowed to create key for this game yet.
    Become a trusted seller to get access to this game.</p> 
  )}
</div>


                    <span className="mb-4">Quantity</span>
                    <div className="quantity-container"> 
                        <div className="flex items-center"> 
                            <input
                                min="0"
                                className="quantity-input"
                                type="number"
                                placeholder="0"
                                value={quantity}
                                onChange={handleQuantityChange}
                            />
                            <span className="text-white">unities</span> 
                        </div>

                        <div className="flex items-center pr-4 text-[#8C94A2]"> 
                            Price: <span className="ml-2 text-white">{price}€</span> 
                        </div>
                    </div>

                    <button className="create-button" onClick={handleCreate}>
                        Create
                    </button>

                    <div className="key-info">
                        <label className="keys-label">Keys</label>
                        <label className="lines-label">Lines: 1</label> 
                    </div>

                    <textarea id="message" rows="8" value={createdKeys} className="custom-textarea" placeholder="Your generated keys will appear here" disabled ></textarea>

                    <div className="centered-button-group"> 
                    <button className="centered-button" onClick={handleCopy}>
              Copy
            </button>
            <button className="centered-button" onClick={handleDownload}>
              Download
            </button>

             
</div>
                </div>

                </div>
        </div>

        
    )

 
}

export default LicenseKeyComponent;