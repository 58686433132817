import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getCheatBySlug } from '../../utils/apiService'; // Ensure this API call function is implemented to fetch cheat details
import Header from '../Home/Header/Header';
import Footer from '../../components/footer/footer';
import './ProductPage.scss'

const ProductPage = () => {
    const { slug } = useParams();
    const [cheat, setCheat] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchCheat = async () => {
            try {
                const data = await getCheatBySlug(slug);
                setCheat(data);
                setLoading(false);
            } catch (err) {
                setError(err.message);
                setLoading(false);
            }
        };

        fetchCheat();
    }, [slug]);

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;
    if (!cheat) return <div>No cheat found!</div>;

    return (
        <>        <div className="container">
            <Header />
            <div className='product-page'>
                <div className="product-layout lg:max-w-[1030px] 2xl:max-w-[1076px] mx-4 lg:mx-auto">
                    <div className='product-header'>
                        <div>
                            <h1 className="product-title">{cheat.name}</h1>
                            <div className="status-section">
                                <div className="status-indicator">
                                    <div className='status-sonar'></div>
                                </div>
                                <p className="status-text">UNDETECTED</p>
                            </div>
                        </div>

                        <a href="/products" className="back-link">
                            <span className="back-arrow"><svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="none"><path fill="#626971" d="M3.219 5.333h8.114v1.334H3.22l3.576 3.576-.943.942L.667 6 5.852.814l.943.943z"></path></svg></span>
                            Go back to store
                        </a>
                    </div>

                    <div className='product-body'>
                        <div className='product-body-image'>
                            <img src={"https://ssz.gg/_next/image?url=https%3A%2F%2Fstorage.sell.app%2Fstore%2F29295%2Flistings%2FPFkidtj4GBjBJ6NVT4EQC3LwEXepXxgDyqWFZMDc.gif&w=1080&q=75"} alt={cheat.name} width="475" height="286" decoding="async" />
                        </div>
                        <p>{cheat.description}</p>
                    </div>
                </div>

            </div>
        </div>
            <Footer />
        </>
    );
};

export default ProductPage;
