import React from 'react';
import './mainpanel.scss'; // Ensure the SCSS file is correctly linked
import { Link } from 'react-router-dom';

const MainPanel = () => {
  return (
    <section className="main-panel">
      <main className="main-content">
        {/* Background image section */}
        <div className="hero-background" style={{ backgroundImage: 'url(../assets/backgrounds/hero.svg)' }}>
          <div className="content-wrapper">
            <p className="tagline">WIDE VARIETY GAMES</p>
            <h1 className="main-title">ELEVATE YOUR GAMEPLAY WITH OUR CHEATS FOR
              <span className="game-name"> <div className="typewriter-effect">Game Name<span className="cursor">|</span></div></span>
            </h1>
            <p className="description">Your #1 Trusted Cheat Provider, offering high-quality, reliable products for amazing prices with top notch customer support.</p>
            <div className="action-buttons">
              <a href="/products" className="cheats-button">OUR CHEATS</a>
              <a href="https://discord.gg/ssz" target="_blank" rel="noopener noreferrer" className="discord-button">JOIN OUR DISCORD</a>
            </div>
          </div>
        </div>
        
        <div className="cheatsLink">
          <h2>Check Out MW3 Cheats!</h2>
          <Link to="/mw3-cheats">Click here for MW3 Cheats and Tips</Link> {/* Internal Link */}
        </div>
          
        {/* Other sections like product highlights, testimonials, etc., follow a similar pattern */}
      </main>
    </section>
  );
};

export default MainPanel;
