import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, Button } from '@mui/material';
import './header.scss'; // Make sure you import the SCSS file
import SignUpModal from '../../../components/SignupModal/SignupModal';
import LoginModal from '../../../components/loginModal/loginModal';
import { Link } from 'react-router-dom';

const Header = () => {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false); // State for dropdown visibility

  const [toggleMenu, setToggleMenu] = useState(false);
  const [showSignUp, setShowSignUp] = useState(false);
  const [showLogin, setShowLogin] = useState(false);


  const handleMouseEnter = () => {
    setIsDropdownVisible(true); // Show the dropdown when hovering
  };

  const handleMouseLeave = () => {
    setIsDropdownVisible(false); // Hide the dropdown when leaving
  };


  const handleSwitchToLogin = () => {
    setShowSignUp(false);
    setShowLogin(true);
  };

  const handleSwitchToSignUp = () => {
    setShowLogin(false);
    setShowSignUp(true);
  };


  return (
    <AppBar position="sticky" sx={{ fontFamily: 'Arame, sans-serif' }} className="app-bar arame-font">
      <Toolbar className="toolbar">
        <div className="left-section">
        <Link to="/"> {/* Redirect to homepage when clicked */}
            <img src="../assets/logo/logo5.png" alt="Your Logo" className="logo" />
          </Link>
        </div>

        <button onClick={() => setToggleMenu(!toggleMenu)} className="burger-menu">
          <img src={toggleMenu ? "../assets/icons/close-menu.svg" : "../assets/icons/burger-menu.svg"} alt={toggleMenu ? "Close Menu" : "Open Menu"} />
        </button>

        <div className="center-section">
          <div className='all-cheats' onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <Typography variant="h8">All Cheats</Typography> {/* Label for hover area */}

            {/* Dropdown menu */}
            {( // Conditionally render based on hover
              <div className="dropdown-menu"> {/* Apply styles for the dropdown */}
                <Link to="/mw3-cheats">MW3 Cheats</Link> {/* Link to MW3 Cheats */}
                <Link to="/fc24-cheats">FC24 Cheats</Link>
                <Link to="/fc25-cheats">FC25 Cheats</Link>
                {/* Add other links for additional cheats */}
              </div>
            )}
          </div>

          <Button color="inherit">
            <img src="../assets/icons/store.svg" /> STORE</Button>
          <Button color="inherit">
            <img src="../assets/icons/status.svg" /> STATUS</Button>
          <Button color="inherit">
            <img src="../assets/icons/faq.svg" /> FAQ</Button>
        </div>

        <div className="right-section">
          <Button
            color="inherit"
            className="discord-button"
            onClick={() => window.open('https://discord.gg/qVEXmFk4DM', '_blank')}
          >
            DISCORD
          </Button>

          <Button color="inherit" className="our-cheats-button">
            <img src="../assets/icons/crosshair.svg" /> OUR CHEATS</Button>

        {/*   <Button
            color="inherit"
            className="sign-up-button"
            onClick={() => setShowSignUp(true)}
          >
            SIGN UP
          </Button>*/}
        </div>
      </Toolbar>
      {showSignUp && <SignUpModal onClose={() => setShowSignUp(false)} onSwitchToLogin={handleSwitchToLogin} />}
      {showLogin && <LoginModal onClose={() => setShowLogin(false)} onSwitchToSignUp={handleSwitchToSignUp} />}
    </AppBar>


  );
};

export default Header;
