import React, { useState } from 'react';
import './DisableKeyComponent.scss';
import { disableLicenseKey } from '../../../../../utils/apiService';

function DisableKeyComponent({ userRole }) {
  const [error, setError] = useState('');
  const [key, setKey] = useState('');
  const [reason, setReason] = useState('');


  const handleDisableKey = async () => {
    try {
      await disableLicenseKey(key, reason);
      setError('');
    } catch (err) {
      setError(err.message);
    }
  };
  
  return (
    <div className="disable-key-container">
      <div className="disable-key-header">
        <h1>Disable keys</h1>
      </div>
      {error && <div className="error-message">{error}</div>} {/* Display error if present */}

      <div className="disable-key-body">
        <div className="input-group">
          <div className='mb2'>Key</div>
          <input
            type="text"
            className="input-field"
            placeholder="XXXXX-XXXXX-XXXXX-XXXXX"
            value={key}
            onChange={(e) => setKey(e.target.value)}
          />
        </div>
        <div class="mb4 ml1"></div>
        <div className="input-group">
          <div className='mb2 mt4'>Reason</div>
          <input
            type="text"
            className="input-field"
            placeholder="Reason"
            value={reason}
            onChange={(e) => setReason(e.target.value)}
          />
        </div>

        <button className="disable-button" onClick={handleDisableKey}>
          Disable
        </button>
      </div>
    </div>
  );
}

export default DisableKeyComponent;
