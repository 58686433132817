import React, { useEffect, useState } from 'react';
import { useAuth } from '../../context/AuthContext';
import UpdateIcon from '@mui/icons-material/Update';
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import VpnKeyIcon from '@mui/icons-material/VpnKey';

import CreateKeysComponent from './Components/Keys/CreateKeysComponent'
import DisplayKeysComponent from './Components/Keys/Display/DisplayKeysComponent';
import DisableKeyComponent from './Components/Keys/Disable/DisableKeyComponent';
import KeyResetComponent from './Components/Keys/Reset/KeyResetComponent';
import { getBalance } from '../../utils/apiService';

import '../../styles/Dashboard.scss';

function Dashboard() {
  const drawerWidth = 240;
  const [activeSection, setActiveSection] = useState('licensekey');

  const { getUserRole, getUserName, logout } = useAuth();
  const [userRole, setUserRole] = useState(null);
  const [userName, setUserName] = useState(null);
  const [balance, setBalance] = useState(0);
  const [lastLogin, setLastLogin] = useState('');
  const isAdmin = userRole === 'admin';

  
  useEffect(() => {
    const fetchUserData = async () => {
      const role = await getUserRole();
      const name = await getUserName();
      const balance = await getBalance(); 
      setUserRole(role);
      setUserName(name);
      setBalance(balance);
    };

    fetchUserData();
  }, [getUserRole, getUserName]);

 


  const sectionDetails = {
    licensekey: {
      icon: <VpnKeyIcon />,
      component: DisplayKeysComponent,
      displayName: 'My Keys',
      submenus: [ // Submenus under 'My Keys'
        { key: 'createKeys', displayName: 'Create Keys', component: (props) => <CreateKeysComponent {...props} /> },
        { key: 'disableKeys', displayName: 'Disable Keys', component: DisableKeyComponent },
        { key: 'hwidReset', displayName: 'HWID Reset', component: KeyResetComponent },
      ],
    },
    myorders: {
      icon: <SubscriptionsIcon />,
      displayName: 'My Orders',
      submenus: [
        { key: 'addFund', displayName: 'Add funds', component: KeyResetComponent },
      ],
    },
    instruction: {
      icon: <UpdateIcon />,
      displayName: 'Instructions',
      submenus: [
        { key: 'commonIssues', displayName: 'Common Issues', component: KeyResetComponent },
        { key: 'presaleQuestions', displayName: 'Presale Questions', component: KeyResetComponent },
        { key: 'customerQuestions', displayName: 'Customers Questions', component: KeyResetComponent },
      ],
    },
  
    // blacklist: { icon: <BlockIcon />, component: BlacklistComponent },
  };

  const findComponentForActiveSection = (sectionDetails, activeSection) => {
    // Check main sections
    const section = sectionDetails[activeSection];
    if (section && section.component) {
      return section.component; // Return the component for the main section
    }
  
    // Check submenus of all sections
    for (const key in sectionDetails) {
      if (sectionDetails[key].submenus) {
        const foundSubmenu = sectionDetails[key].submenus.find(
          (submenu) => submenu.key === activeSection
        );
        if (foundSubmenu) {
          return foundSubmenu.component; // Return the component for the submenu
        }
      }
    }
  
    return null; // If no component is found
  };

const renderSection = () => {
  const ActiveComponent = findComponentForActiveSection(sectionDetails, activeSection);
  return ActiveComponent ? <ActiveComponent userRole={userRole} /> : <div>Select a section</div>;
};

  return (
    <div className="dashboard">
      <div className="app-bar2">
        <div className="toolbar">
          <div className="logo">
            <img src="/path/to/your/logo.png" alt="Company Logo" />
          </div>

          <div className="user-info">
            <span className="last-login">Last Login: Apr 22, 2024 1:35 PM</span>
            <span className="balance"> <img src="../assets/icons/balance.svg" className="balance-icon" /> <span className="balance-number">{balance} €
 </span></span>
            <div className='user-info-details'>
              <span className="username">
                {userName}
              </span>
              <span className="user-role">({userRole})</span>
            </div>
          </div>
        </div>
      </div>

      <div className="drawer">
        <ul className="drawer-list">
          {Object.entries(sectionDetails).map(([key, { icon, displayName, submenus }]) => (
            <>
              {/* Main menu item */}
              <li
                className="drawer-list-item"
                key={key}
                onClick={() => setActiveSection(key)}
              >
                <div className="icon">{icon}</div>
                <div className="text">{displayName}</div>
              </li>

              {/* Submenus if they exist */}
              {submenus && (
                <ul className="submenu-list">
                  {submenus.map((submenu, idx) => (
                    <li
  className="drawer-list-item"
  key={submenu.key}
  onClick={() => {
    console.log('Setting active section:', submenu.key); // Log the key being set
    setActiveSection(submenu.key);
  }}
>
  <div className="icon">{submenu.icon}</div>
  <div className="text">{submenu.displayName}</div>
</li>
                  ))}

                </ul>
              )}

            </>
          ))}
          <li className="drawer-list-item" onClick={logout}>
            <div className="icon"><ExitToAppIcon /></div>
            <div className="text">Logout</div>
          </li>
        </ul>
      </div>


      <div className="content">
        {renderSection()}
      </div>
    </div>
  );
}

export default Dashboard;
